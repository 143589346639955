.container {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.2);
    color: rgb(255, 255, 255);
    border-radius: 99px;
    display: flex;

    backdrop-filter: blur(8px);
    padding: 3px 20px;
}

.help {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.helpLabel {
    margin-right: 5px;
}
