.panel {
    border-radius: 10px 10px 0 0;
}

.imageDiv {
    canvas {
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        height: 100%;
        border-radius: 0.375rem;
    }
}

@keyframes rotatingAnim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotating {
    animation-name: rotatingAnim;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
