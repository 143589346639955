.pointer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 120px;
    width: 120px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

.hints {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.itemHint {
    flex-basis: 50%;
}

.issue {
    > div {
        opacity: 0.5;
        background-color: rgba(0, 0, 0, 0.2);
        color: rgb(255, 255, 255);
        border-radius: 99px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        backdrop-filter: blur(8px);
        padding: 3px 20px;
    }
}
